import React from 'react';

function Step2({ valuation, valuationDetails, setStep }) {
  return (
    <div className="step2-container">
      {/* AdSense Ad */}
      <div
        className="adsense"
        dangerouslySetInnerHTML={{
          __html: `
            <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5950615989586230"
             crossorigin="anonymous"></script>
            <ins class="adsbygoogle"
                 style="display:block"
                 data-ad-client="ca-pub-5950615989586230"
                 data-ad-slot="2213108757"
                 data-ad-format="auto"
                 data-full-width-responsive="true"></ins>
            <script>
                 (adsbygoogle = window.adsbygoogle || []).push({});
            </script>
          `,
        }}
      ></div>

      {/* Valuation Display */}
      <div className="valuation-box">
        <p id="valuation-display">
          Your Estimated Newsletter Valuation: <strong>{valuation}</strong>
        </p>
      </div>

      {/* Valuation Breakdown */}
      <div className="valuation-details-box">
        <h3>How the Valuation Was Calculated:</h3>
        <div className="valuation-details">
          <pre>{valuationDetails}</pre>
        </div>
      </div>

      {/* Link to Blog Post */}
      <div className="blog-link">
        <a href="/blog/increase-newsletter-value">
          How to Increase the Value of Your Newsletter
        </a>
      </div>

      {/* Support with a Donation */}
      <div className="support-link">
        <a href="https://www.paypal.com/donate/?hosted_button_id=JYAPBENGNYQBU" target="_blank" rel="noopener noreferrer">
          Support with a tip
        </a>
      </div>

      {/* Go Back Button */}
      <button className="button-prev" onClick={() => setStep(1)}>
        Go Back
      </button>
    </div>
  );
}

export default Step2;
