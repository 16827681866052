import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import './App.css';
import Step1 from './components/Step1';
import Step2 from './components/Step2';
import BlogPost from './components/BlogPost';
import About from './components/About';
import ExternalLinks from './components/ExternalLinks';
import { doc, getDoc, updateDoc, setDoc } from 'firebase/firestore';
import db from './firebase';

function App() {
  const [formValues, setFormValues] = useState({
    subscribers: '',
    newSubscribers: '',
    avgMonthlyRevenue: '',
    contentSource: 'myself',
    writingTime: '',
    hourlyRate: '',
    postCost: '',
    adsRunning: 'no',
    adsCost: '0',
    monthlyExpenses: '0',
    frequency: 'monthly',
  });

  const [valuation, setValuation] = useState(null);
  const [valuationDetails, setValuationDetails] = useState('');
  const [valuationCount, setValuationCount] = useState(0);
  const [step, setStep] = useState(1);

  useEffect(() => {
    const fetchValuationCount = async () => {
      try {
        const docRef = doc(db, 'stats', 'valuationCounter');
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setValuationCount(docSnap.data().count);
        } else {
          await setDoc(doc(db, 'stats', 'valuationCounter'), { count: 0 });
          setValuationCount(0);
        }
      } catch (error) {
        console.error('Error fetching valuation count:', error);
      }
    };

    fetchValuationCount();
  }, []);

  const handleChange = (input) => (e) => {
    setFormValues({ ...formValues, [input]: e.target.value });
  };

  const updateValuationCounter = async () => {
    try {
      const docRef = doc(db, 'stats', 'valuationCounter');
      await updateDoc(docRef, {
        count: valuationCount + 1,
      });
    } catch (error) {
      console.error('Error updating valuation count:', error);
    }
  };

  const calculateValuation = () => {
    const {
      subscribers,
      newSubscribers,
      avgMonthlyRevenue,
      contentSource,
      writingTime,
      hourlyRate,
      postCost,
      adsRunning,
      adsCost,
      monthlyExpenses,
      frequency,
    } = formValues;

    let numPostsPerMonth;
    switch (frequency) {
      case 'daily':
        numPostsPerMonth = 30;
        break;
      case 'weekly':
        numPostsPerMonth = 4;
        break;
      case 'bi-weekly':
        numPostsPerMonth = 2;
        break;
      case 'monthly':
      default:
        numPostsPerMonth = 1;
        break;
    }

    const estimatedNewSubscribers3Years = parseFloat(newSubscribers || 0) * 36;
    const growthRatio = subscribers > 0 ? (estimatedNewSubscribers3Years / subscribers) * 100 : 0;
    const annualRevenueIn3Years = (growthRatio / 100 + 1) * (parseFloat(avgMonthlyRevenue || 0) * 12);

    let writingCost;
    if (contentSource === 'myself') {
      writingCost = numPostsPerMonth * parseFloat(writingTime || 0) * parseFloat(hourlyRate || 0);
    } else {
      writingCost = numPostsPerMonth * parseFloat(postCost || 0);
    }

    const totalCosts = (writingCost + (adsRunning === 'yes' ? parseFloat(adsCost || 0) : 0) + parseFloat(monthlyExpenses || 0)) * 12;
    let expectedProfit = annualRevenueIn3Years - totalCosts;

    if (expectedProfit > 0) {
      expectedProfit /= 1.33;
    } else {
      expectedProfit = 0;
    }

    const finalValuation = Math.max(0, Math.round(expectedProfit * 3)).toLocaleString();
    setValuation(`$${finalValuation}`);

    const details = `
    - Estimated New Subscribers in 3 years: ${Math.round(estimatedNewSubscribers3Years).toLocaleString()}
    - Subscribers growth over 3 years: ${Math.round(growthRatio)}%
    - Annual revenue in 3 years: $${Math.round(annualRevenueIn3Years).toLocaleString()}
    - Writing cost (annualized): $${Math.round(writingCost * 12).toLocaleString()}
    - Ads & Other expenses (annualized): $${Math.round((adsRunning === 'yes' ? parseFloat(adsCost || 0) : 0) + parseFloat(monthlyExpenses || 0)).toLocaleString() * 12}
    - Total costs (annualized): $${Math.round(totalCosts).toLocaleString()}
    - Expected annual profit (adjusted for time value of money): $${Math.round(expectedProfit).toLocaleString()}
    - Final valuation (Profit * 3): $${finalValuation}
    `;
    setValuationDetails(details);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    calculateValuation();
    await updateValuationCounter();
    setValuationCount((prevCount) => prevCount + 1);
    setStep(2);
  };

  return (
    <Router>
      <div className="App">
        {/* Header with navigation links */}
        <header className="header">
          <nav>
            <Link to="/">Valuation Tool</Link>
            <Link to="/about">About</Link>
            <Link to="/external-links">Additional Resources</Link>
          </nav>
        </header>

        {/* Banner - only displayed on the home page */}
        <Routes>
          <Route
            path="/"
            element={
              <>
                <div className="banner">
                  <h1>Do you know how much your newsletter is worth?</h1>
                  <h2>Calculate in under 2 minutes, for free!</h2>
                </div>

                {step === 1 ? (
                  <Step1
                    handleSubmit={handleSubmit}
                    handleChange={handleChange}
                    values={formValues}
                  />
                ) : (
                  <Step2
                    valuation={valuation}
                    valuationDetails={valuationDetails}
                    setStep={setStep}
                  />
                )}
              </>
            }
          />
          <Route path="/about" element={<About />} />
          <Route path="/external-links" element={<ExternalLinks />} />
          <Route path="/blog/increase-newsletter-value" element={<BlogPost />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
