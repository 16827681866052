// firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAaKqsuXxQxxhapAHgRIHGcL_sJx8VG65k",
  authDomain: "newsletter-counter.firebaseapp.com",
  projectId: "newsletter-counter",
  storageBucket: "newsletter-counter.appspot.com",
  messagingSenderId: "874283998670",
  appId: "1:874283998670:web:8d44118ba43d06ab90df9f"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

export default db;