// src/components/About.js
import React from 'react';
import './About.css'; // Import a separate CSS file for About-specific styling

function About() {
  return (
    <div className="about-container">
      <h1>About Newsletter Valuation Tool</h1>
      <p className="intro">
        Welcome to the Newsletter Valuation Tool! Our goal is to help newsletter creators understand and maximize the value of their work through data-driven insights.
      </p>

      <section className="mission">
        <h2>Our Mission</h2>
        <p>
          I believe that every newsletter has a story and value to tell. The mission is to allow the newsletter writers/owners understand the value of the newsletter.
        </p>
      </section>

      <section className="why-valuation">
        <h2>Why Valuation Matters</h2>
        <p>
          Understanding the value of your newsletter is critical for strategic decisions, whether you're looking to grow, monetize, or even explore sponsorships.
        </p>
      </section>

      <blockquote className="quote">
        "The value of a newsletter lies in its connection with readers. This tool helps you quantify that connection."
      </blockquote>
      

      {/* Support with a Donation */}
      <div className="support-link">
        <a href="https://www.paypal.com/donate/?hosted_button_id=JYAPBENGNYQBU" target="_blank" rel="noopener noreferrer">
          Support with a tip
        </a>
      </div>
      
    </div>
  );
}

export default About;
